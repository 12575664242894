import { forwardRef } from 'react';
import cn from 'classnames';
export const Section = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
   ({ className, children, ...props }, ref) => {
      return (
         <div ref={ref} className={cn('bg-white rounded-default shadow', className)} {...props}>
            {children}
         </div>
      );
   }
);
Section.displayName = 'Section';
