import { CheckedListItem, UncheckedListItem, WarningIcon } from '~/svgs';
import { AccountStatuses } from '~/auth/use-account-status';
import { Section } from '~/components/common/section';
import { useAccountStatus, useAuth } from '~/auth';
import { Translate } from '~/i18n';
import { NextLink } from '~/i18n';
import cn from 'classnames';

type CheckpointItemProps = {
   isCurrent?: boolean;
   isCompleted?: boolean;
   title?: React.ReactNode;
   children?: React.ReactNode;
};
const CheckpointItem = ({ children, isCompleted, isCurrent, title }: CheckpointItemProps) => {
   const { status } = useAccountStatus('reseller');
   return (
      <li className={cn('px-8 py-3', isCurrent && 'bg-gray-100')}>
         <div className="flex items-center">
            {isCompleted ? (
               <CheckedListItem />
            ) : isCurrent ? (
               status == AccountStatuses.RequiresUpdate ? (
                  <WarningIcon width={31} height={31} />
               ) : (
                  <UncheckedListItem
                     className={cn(
                        '!stroke-current',
                        status == AccountStatuses.UnderReview ? 'text-[#FAA515]' : ' text-[#00A248]'
                     )}
                  />
               )
            ) : (
               <div className="w-8 h-8 bg-gray-100 rounded-full"></div>
            )}
            <span className={cn(isCurrent && 'font-bold', 'flex-grow ltr:ml-4 rtl:mr-4')}>
               {/* <Translate id="dashboard:create an account" /> */}
               {title}
            </span>
            {children}
         </div>
      </li>
   );
};

type ProfileSectionResellerProps = {
   hideProgressBar?: boolean;
};

export const ProfileSectionReseller = ({ hideProgressBar }: ProfileSectionResellerProps) => {
   const { status } = useAccountStatus('reseller');
   const { user } = useAuth();
   const resellFirstProduct = user?.profile_strength?.resell_first_product;
   const verifyAccount = user?.profile_strength?.verified_business_profile;

   const renderContinueProfileButton = () => {
      switch (status) {
         case AccountStatuses.CategoriesIncomplete:
            return (
               <NextLink
                  href="/reseller/profile/categories"
                  className="!px-6 font-bold peer btn primary relative z-10">
                  <Translate id="dashboard:change requested" />
               </NextLink>
            );
         case AccountStatuses.DetailsIncomplete:
            return (
               <NextLink
                  href="/reseller/profile/details"
                  className="!px-6 font-bold peer btn primary relative z-10">
                  <Translate id="dashboard:verify now" />
               </NextLink>
            );
         case AccountStatuses.FilesIncomplete:
            return (
               <NextLink
                  href="/reseller/profile/files"
                  className="!px-6 font-bold peer btn primary relative z-10">
                  <Translate id="dashboard:verify now" />
               </NextLink>
            );
         case AccountStatuses.RequiresUpdate:
            return (
               <NextLink
                  href="/reseller/profile/personal-info"
                  className="!px-6 font-bold peer btn primary relative z-10">
                  <Translate id="dashboard:change requested" />
               </NextLink>
            );
         default:
            return <Translate id="dashboard:under review" />;
      }
   };

   const isCompleted =
      status === AccountStatuses.Completed || status === AccountStatuses.HasNoProducts;

   const renderProgressBar = () => {
      let progressStrengthValue = 1;
      if (isCompleted) progressStrengthValue += 1;
      if (resellFirstProduct) progressStrengthValue += 1;

      return (
         <div className="flex items-center space-x-0.5 rtl:space-x-reverse flex-shrink-0">
            {[...Array(3)].map((_key, index) => (
               <div
                  key={index}
                  className={cn(
                     'block w-[24px] md:w-[48px] lg:w-[72px] h-2 sm:h-[10px]',
                     progressStrengthValue > index ? 'bg-primary' : ' bg-gray-100',
                     index === 0 && 'ltr:rounded-l-full rtl:rounded-r-full'
                  )}></div>
            ))}
            <div className="w-10 h-10 border-2 border-gray-100 rounded-full ltr:!-ml-1 rtl:!-mr-1 bg-white flex items-center justify-center">
               <img
                  src="/images/dashboard/trophy.svg"
                  alt=""
                  width="20px"
                  height="20px"
                  className="w-5 h-5"
               />
            </div>
         </div>
      );
   };

   return (
      <Section className="py-8">
         <div className="px-8">
            <div className="flex flex-wrap items-center justify-between">
               <span className="text-xl font-bold sm:text-2xl">
                  <Translate id="profile:what next" />
               </span>
               {!hideProgressBar && renderProgressBar()}
            </div>
         </div>
         <hr className="mt-5 border border-gray-100 mb-7" />
         <ul className="space-y-4">
            <CheckpointItem title={<Translate id="dashboard:create an account" />} isCompleted />
            <CheckpointItem
               title={<Translate id="dashboard:verify the business profile" />}
               isCompleted={verifyAccount}
               isCurrent={!verifyAccount}>
               {!verifyAccount && (
                  <div className="relative group">
                     {status !== AccountStatuses.UnderReview && (
                        <span className="absolute inline-flex w-full h-full group-hover:animate-none rounded-default opacity-40 bg-primary animate-animate-ping-small"></span>
                     )}
                     {renderContinueProfileButton()}
                  </div>
               )}
            </CheckpointItem>
            <CheckpointItem
               title={<Translate id="dashboard:resell the first product" />}
               isCompleted={resellFirstProduct}
               isCurrent={!resellFirstProduct}>
               {isCompleted && (
                  <div className="relative group">
                     <span className="absolute inline-flex w-full h-full group-hover:animate-none rounded-default opacity-40 bg-primary animate-animate-ping-small"></span>
                     <div className="!px-6 font-bold peer btn primary relative z-10">
                        <Translate id="dashboard:start now" />
                     </div>
                  </div>
               )}
            </CheckpointItem>
         </ul>
      </Section>
   );
};
