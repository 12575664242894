import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import cn from 'classnames';
// import useTheme, { Theme } from '~/theme';
import css from './styles.module.css';
import { reseller_links, supplier_links, coach_links } from '../_links';
import { HeaderLink } from './link';
import { MenuIcon } from '~/svgs';
import { useLayout } from '../provider';
import { useRef } from 'react';
import useOnClickOutside from '~/hooks/on-click-outside';

const Header = () => {
   // const { theme } = useTheme();
   const { open, toggleMenu } = useLayout();
   const router = useRouter();
   const menuRef = useRef<HTMLDivElement>(null);
   useOnClickOutside(menuRef, () => {
      if (open) toggleMenu(false);
   });

   Router.events.on('routeChangeStart', () => NProgress.start());
   Router.events.on('routeChangeComplete', () => NProgress.done());
   Router.events.on('routeChangeError', () => NProgress.done());
   const userType = router.asPath.includes('reseller')
      ? 'reseller'
      : router.asPath.includes('coach')
      ? 'coach'
      : 'supplier';

   const links =
      userType === 'coach'
         ? coach_links
         : userType === 'reseller'
         ? reseller_links
         : supplier_links;

   return (
      <header className="fixed top-0 z-50 h-screen lg:sticky ltr:left-0 rtl:right-0">
         <nav
            ref={menuRef}
            className={cn(
               'h-full transition-all duration-[250ms] bg-primary',
               open ? 'w-72' : 'lg:w-20 w-0 lg:overflow-visible overflow-hidden'
            )}>
            <div className="py-6">
               <button
                  className={cn(css.item, 'mb-9 text-white')}
                  onClick={() => toggleMenu()}
                  aria-label="menu">
                  <MenuIcon />
               </button>
               <ul className={cn(open ? css.expanded : css.collapsed)}>
                  {links.map((link, index) => (
                     <li key={index} className="mb-4">
                        <HeaderLink {...link} expanded={open} />
                     </li>
                  ))}
               </ul>
            </div>
         </nav>
      </header>
   );
};

export default Header;
