import { GetStaticProps, GetStaticPaths } from 'next';
import { withRedirectOnAuth } from '~/auth';
import AuthLayout from '~/components/auth';
import SignupOptions from '~/components/auth/signup-options';
import { getLanguagesPaths } from '~/utils/translate';

const SignupPage = () => {
   return (
      <AuthLayout bgImg="/images/auth/supplier/login-bg.jpg">
         <SignupOptions />
      </AuthLayout>
   );
};

export const getStaticPaths: GetStaticPaths = async () => {
   return {
      paths: getLanguagesPaths([{ id: 'test' }]),
      fallback: false,
   };
};

/******************************************************************
 *
 *  THIS PAGE IS USING STATIC SITE GENERATION
 *
 ******************************************************************/

/* ----- You can access the all params inside getStaticProps ---- */

export const getStaticProps: GetStaticProps = async () => {
   return {
      props: {},
   };
};

SignupPage.hideHeader = true;
SignupPage.hideSubheader = true;
SignupPage.noContainer = true;

export default withRedirectOnAuth(SignupPage);
