import axios from 'axios';
import jsCookie from 'js-cookie';
/** -------------------------------------------------------------------------- **/
/**        This file should have all axios instances, add more if needed       **/
/** -------------------------------------------------------------------------- **/
/** --------- Make sure to add their keys inside enviroment variables -------- **/
const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;
const token = process.env.NEXT_PUBLIC_API_TOKEN;
const Axios = axios.create({
   baseURL,
   headers: { token },
});
Axios.interceptors.request.use(function (config) {
   if (typeof window !== 'undefined') {
      const token = jsCookie.get('token');
      if (token) {
         config.headers.Authorization = 'Bearer ' + token;
      }
   }
   return config;
});
/** --------------------------- Interceptor example -------------------------- **/
/** -------------------------------------------------------------------------- **/
/**           Read axios docs: {@link https://github.com/axios/axios}          **/
/** -------------------------------------------------------------------------- **/
Axios.interceptors.response.use(
   function (response) {
      return Promise.resolve(response);
   },
   function (error) {
      return Promise.reject(error);
   }
);
export default Axios;
