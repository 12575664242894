import { FC } from 'react';
import { createContext, useContext } from 'react';

export enum Theme {
   SUPPLIER = 'supplier',
   RESELLER = 'reseller',
   COACH = 'coach',
}

type Themes = Theme;

type ThemeContextProps = {
   theme: Themes;
};

export const ThemeContext = createContext<ThemeContextProps>({
   theme: Theme.SUPPLIER,
});

export default function useTheme() {
   return useContext<ThemeContextProps>(ThemeContext);
}

export const ThemeProvider: FC<ThemeContextProps> = ({ children, theme }) => {
   return <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>;
};
