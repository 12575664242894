import common from './common.json';
import validation from './validation.json';
import dashboard from './dashboard.json';
import createproduct from './create-product.json';
import profile from './profile.json';
import auth from './auth.json';
import bookings from './bookings.json';
import productsList from './products-list.json';
import productDetails from './product-details.json';
import createLink from './create-link.json';
import payout from './payout.json';
import notification from './notification.json';
import reviews from './reviews.json';
import operations from './operations.json';
const data = {
   common,
   validation,
   dashboard,
   createproduct,
   profile,
   auth,
   bookings,
   productsList,
   productDetails,
   createLink,
   payout,
   notification,
   reviews,
   operations,
};

export type TranslationShape = typeof data;

export default data;
