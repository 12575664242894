import Axios from '~/utils/axios';
import { useQuery } from 'react-query';

type useNotificationListType = {
   userType: string;
   pageNumber: number;
};
const useNotificationList = ({ pageNumber, userType }: useNotificationListType) => {
   const getNotificationList = async () => {
      const { data } = await Axios.get(`/hy-${userType}/v1/notifications`, {
         params: { page: pageNumber },
      });

      return data;
   };

   return useQuery(['notifications', { pageNumber: pageNumber }], getNotificationList, {
      refetchOnWindowFocus: false,
   });
};

export default useNotificationList;
