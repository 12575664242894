import { forwardRef } from 'react';
import cn from 'classnames';
import { SubmitLoader } from '../loaders/submit';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
   theme?: 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary';
   type?: 'button' | 'submit' | 'reset';
   loading?: boolean;
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
   ({ theme, type = 'button', loading, children, className, ...props }, ref) => {
      switch (theme) {
         case 'primary':
            return (
               <button type={type} className={cn('btn', 'primary', className)} ref={ref} {...props}>
                  {children}
                  {loading && <SubmitLoader className="ltr:ml-2 rtl:mr-2" size={20} />}
               </button>
            );
         case 'secondary':
            return (
               <button
                  type={type}
                  className={cn('btn', 'secondary', className)}
                  ref={ref}
                  {...props}>
                  {children}
                  {loading && <SubmitLoader className="ltr:ml-2 rtl:mr-2" size={20} />}
               </button>
            );

         case 'outline-primary':
            return (
               <button
                  type={type}
                  className={cn('btn', 'outline-primary', className)}
                  ref={ref}
                  {...props}>
                  {children}
                  {loading && <SubmitLoader className="ltr:ml-2 rtl:mr-2" size={20} />}
               </button>
            );
         case 'outline-secondary':
            return (
               <button
                  type={type}
                  className={cn('btn', 'outline-secondary', className)}
                  ref={ref}
                  {...props}>
                  {children}
                  {loading && <SubmitLoader className="ltr:ml-2 rtl:mr-2" size={20} />}
               </button>
            );

         default:
            return (
               <button type={type} className={cn('btn', className)} ref={ref} {...props}>
                  {children}
                  {loading && <SubmitLoader className="ltr:ml-2 rtl:mr-2" size={20} />}
               </button>
            );
      }
   }
);
Button.displayName = 'Button';
