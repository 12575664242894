import { CheckpointItem } from '~/components/common/checkpoint-item';
import { AccountStatuses } from '~/auth/use-account-status';
import { Section } from '~/components/common/section';
import { useAccountStatus, useAuth } from '~/auth';
import { Translate } from '~/i18n';
import { NextLink } from '~/i18n';
import cn from 'classnames';

type ProfileSectionProps = {
   hideProgressBar?: boolean;
};
export const ProfileSection = ({ hideProgressBar = false }: ProfileSectionProps) => {
   const { status } = useAccountStatus();
   const { user } = useAuth();
   const productList = user?.product_list || [];
   const publishedFirstProduct = user?.profile_strength?.published_first_product;
   const verifyAccount = user?.profile_strength?.verified_business_profile;
   const firstProduct = productList?.length ? productList[0] : {};

   // const renderContinueProfileButton = () => {
   //    switch (status) {
   //       case AccountStatuses.CategoriesIncomplete:
   //          return (
   //             <NextLink
   //                href="/supplier/profile/categories"
   //                className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800  relative z-10">
   //                <Translate id="dashboard:complete" />
   //             </NextLink>
   //          );
   //       case AccountStatuses.DetailsIncomplete:
   //          return (
   //             <NextLink
   //                href="/supplier/profile/details"
   //                className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800  relative z-10">
   //                <Translate id="dashboard:complete" />
   //             </NextLink>
   //          );
   //       case AccountStatuses.FilesIncomplete:
   //          return (
   //             <NextLink
   //                href="/supplier/profile/files"
   //                className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800  relative z-10">
   //                <Translate id="dashboard:complete" />
   //             </NextLink>
   //          );
   //       case AccountStatuses.RequiresUpdate:
   //          return (
   //             <NextLink
   //                href="/supplier/profile/personal-info"
   //                className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800 relative z-10">
   //                <Translate id="dashboard:action required" />
   //             </NextLink>
   //          );
   //       case AccountStatuses.Untouched:
   //          return (
   //             <NextLink
   //                href="/supplier/profile/details"
   //                className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800 relative z-10">
   //                <Translate id="dashboard:complete" />
   //             </NextLink>
   //          );
   //       default:
   //          return (
   //             <div className="italic">
   //                <Translate id="dashboard:in review" />
   //             </div>
   //          );
   //    }
   // };

   const renderNewProductButton = () => {
      if (!verifyAccount) return;
      switch (firstProduct?.status) {
         case 'Under Review':
            return (
               <div className="italic">
                  <Translate id="dashboard:in review" />
               </div>
            );

         case 'Requires Update':
            return (
               <NextLink
                  href={`/supplier/products/${firstProduct?._id}/edit/main-details`}
                  className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800 relative z-10">
                  <Translate id="dashboard:action required" />
               </NextLink>
            );
         case 'Draft':
            return (
               <NextLink
                  href={`/supplier/products/${firstProduct?._id}/edit/main-details`}
                  className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800 relative z-10">
                  <Translate id="dashboard:complete" />
               </NextLink>
            );
         default:
            return (
               <NextLink
                  href="/supplier/products/main-details"
                  className="!px-6 font-bold peer btn bg-yellow-400 text-blue-800 relative z-10">
                  <Translate id="dashboard:get started" />
               </NextLink>
            );
      }
   };

   const renderProgressBar = () => {
      let progressStrengthValue = 1;
      if (verifyAccount) progressStrengthValue += 1;
      if (publishedFirstProduct) progressStrengthValue += 1;

      return (
         <div className="flex items-center space-x-0.5 rtl:space-x-reverse flex-shrink-0">
            {[...Array(3)].map((_key, index) => (
               <div
                  key={index}
                  className={cn(
                     'sm:block hidden w-[24px] md:w-[48px] lg:w-[72px] h-2 sm:h-[10px]',
                     progressStrengthValue > index ? 'bg-primary' : ' bg-gray-100',
                     index === 0 && 'ltr:rounded-l-full rtl:rounded-r-full'
                  )}></div>
            ))}
            <div className="w-10 h-10 border-2 border-gray-100 rounded-full ltr:!-ml-1 rtl:!-mr-1 bg-white flex items-center justify-center">
               <img
                  src="/images/dashboard/trophy.svg"
                  alt=""
                  width="20px"
                  height="20px"
                  className="w-5 h-5"
               />
            </div>
         </div>
      );
   };

   return (
      <Section className="py-8">
         <div className="px-8">
            <div className="flex flex-wrap items-center justify-between">
               <span className="text-xl font-bold sm:text-2xl">
                  <Translate id="profile:what next" />
               </span>
               {!hideProgressBar && renderProgressBar()}
            </div>
         </div>
         <hr className="mt-5 border border-gray-100 mb-7" />
         <ul className="space-y-4">
            <CheckpointItem title={<Translate id="dashboard:create an account" />} isCompleted />
            <CheckpointItem
               title={<Translate id="dashboard:verify the business profile" />}
               isCompleted={user?.profile_strength?.verified_business_profile}
               isCurrent={!verifyAccount}
               isUnderReview={!verifyAccount && status == AccountStatuses.UnderReview}
               requiresUpdate={!verifyAccount && status == AccountStatuses.RequiresUpdate}>
               {!verifyAccount && (
                  <div className="relative group">
                     {status !== AccountStatuses.UnderReview && (
                        <span className="absolute inline-flex w-full h-full group-hover:animate-none rounded-default opacity-40 bg-primary animate-animate-ping-small"></span>
                     )}
                     {/* {renderContinueProfileButton()} */}
                  </div>
               )}
            </CheckpointItem>
            <CheckpointItem
               title={<Translate id="dashboard:publish the first product" />}
               isCompleted={publishedFirstProduct}
               isCurrent={verifyAccount}
               isUnderReview={firstProduct?.status !== 'active'}
               requiresUpdate={
                  !publishedFirstProduct && firstProduct?.status === 'Requires Update'
               }>
               {!publishedFirstProduct && (
                  <div className="relative group">
                     {firstProduct?.status !== 'Under Review' && (
                        <span className="absolute inline-flex w-full h-full group-hover:animate-none rounded-default opacity-40 bg-primary animate-animate-ping-small"></span>
                     )}
                     {renderNewProductButton()}
                  </div>
               )}
            </CheckpointItem>
            {/* <CheckpointItem title={<Translate id="dashboard:share products on social media" />} />
            <CheckpointItem title={<Translate id="dashboard:publish on other sales channels" />} /> */}
         </ul>
      </Section>
   );
};
