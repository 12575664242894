export function parseMessage(message: string, values: Record<string, string> = {}) {
   if (typeof message !== 'string') return message;
   const regex = /\{{(.*?)\}}/g;
   const matches = message.match(regex);

   matches?.forEach(key => {
      const strippedKey = key.replace(/(\{|\})/g, '').trim();
      const value = values[strippedKey];
      if (!value) console.warn(`${strippedKey} is not provided as a parameter`);
      else {
         message = message.replace(key, value);
      }
   });

   return message.replace(regex, '');
}

/**
 * Expects a positive number between 0-5999, and returns hours and seconds
 * string like so: 00:00 to 99:50
 * */
export function parseTime(time: number) {
   if (time < 0 || time > 5999) {
      return 'invalid time input';
   }

   const remainder = time % 60;
   const quotient = (time - remainder) / 60;
   const remainderStr = remainder <= 9 ? `0${remainder}` : `${remainder}`;

   return quotient <= 9 ? `0${quotient}:${remainderStr}` : `${quotient}:${remainderStr}`;
}
