import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { NextLink } from '~/i18n';
import { LinkAttributes } from '../_links';
import css from './styles.module.css';
import config from '~/translation.json';
import cn from 'classnames';
import { useEffect } from 'react';
import { useAccountStatus } from '~/auth';
import { AccountStatuses } from '~/auth/use-account-status';
import { ActionPreventedAlert } from '~/components/common/alert/action-prevented';

const { languages } = config;

type HeaderLinkProps = LinkAttributes & { expanded?: boolean };

export const HeaderLink = ({
   href,
   text,
   icon,
   children,
   expanded,
   needsReview,
}: HeaderLinkProps) => {
   const { status } = useAccountStatus();
   const router = useRouter();
   const regex = new RegExp(`^/(${Object.keys(languages).join('|')})`);
   const path = `${router.asPath.replace(regex, ``)}`;
   const active = path.startsWith(href);
   const [showPrevent, setShowPrevent] = useState(false);

   const [open, setOpen] = useState(active);
   const [hover, setHover] = useState(false);

   const underReview =
      status === AccountStatuses.UnderReview || status === AccountStatuses.RequiresUpdate;
   const notReviewed = (needsReview && underReview) || false;

   useEffect(() => {
      if (expanded) setHover(false);
   }, [expanded]);

   const animation = {
      show: {
         height: 'auto',
         opacity: 1,
         transitionEnd: {
            display: 'block',
         },
      },
      hide: {
         height: 0,
         opacity: 0,
         transitionEnd: {
            display: 'none',
         },
      },
   };

   const toggle = () => setOpen(prev => !prev);
   const closePrevent = () => setShowPrevent(false);

   const handleUnderReviewClick = (e: any, prevent: boolean) => {
      if (prevent) {
         e.preventDefault();
         setShowPrevent(true);
      }
   };

   return (
      <div
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         onFocus={() => setHover(true)}
         onBlur={() => setHover(false)}
         className="relative">
         <ActionPreventedAlert open={showPrevent} onClose={closePrevent} />
         {!expanded && children && hover && (
            <div className="absolute z-30   py-4 ltr:left-full rtl:right-full bg-primary ltr:rounded-r-md rtl:rounded-l-md">
               <ul className="block overflow-hidden text-white">
                  {children.map((child, index) => (
                     <li key={index} className="mt-4 first:mt-0 whitespace-nowrap">
                        <NextLink
                           href={child.href}
                           exact
                           onClick={(e: any) =>
                              handleUnderReviewClick(e, (child.needsReview && underReview) || false)
                           }
                           className={cn(
                              'opacity-70 focus:opacity-100 hover:opacity-100 transition-all duration-[250ms] px-9',
                              child.needsReview && underReview && 'line-through'
                           )}
                           activeClassName="!opacity-100">
                           {child.text}
                        </NextLink>
                     </li>
                  ))}
               </ul>
            </div>
         )}
         {children ? (
            <button
               onClick={toggle}
               className={cn(css.link, (active || (open && expanded)) && css.active)}>
               <div>
                  <img className={css.icon} width="22px" height="22px" alt="" src={icon} />
                  {expanded && (
                     <div>
                        <span>{text}</span>
                     </div>
                  )}
               </div>
            </button>
         ) : (
            <NextLink
               onClick={(e: any) => handleUnderReviewClick(e, notReviewed)}
               href={href}
               className={css.link}
               exact={!children}
               activeClassName={css.active}>
               <div>
                  <img className={css.icon} width="22px" height="22px" alt="" src={icon} />
                  {expanded && (
                     <div>
                        <span className={cn(notReviewed && '!opacity-40')}>{text}</span>
                     </div>
                  )}
               </div>
            </NextLink>
         )}
         {children && (
            <motion.ul
               variants={animation}
               initial="hide"
               animate={expanded && open ? 'show' : 'hide'}
               transition={{ ease: 'easeInOut', duration: 0.2 }}
               className="block pb-3 overflow-hidden text-white bg-black ltr:pl-20 rtl:pr-20 bg-opacity-40">
               {children.map((child, index) => (
                  <li key={index} className="mt-4 first:mt-2 whitespace-nowrap">
                     <NextLink
                        href={child.href}
                        exact
                        onClick={(e: any) =>
                           handleUnderReviewClick(e, (child.needsReview && underReview) || false)
                        }
                        className="opacity-60 focus:opacity-100 hover:opacity-100 transition-all duration-[250ms]"
                        activeClassName="!opacity-100">
                        <span className={cn(child.needsReview && underReview && 'line-through')}>
                           {child.text}
                        </span>
                     </NextLink>
                  </li>
               ))}
            </motion.ul>
         )}
      </div>
   );
};
