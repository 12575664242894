import useAuth from './useAuth';

type StepsList = 'categories' | 'details' | 'files';

export enum AccountStatuses {
   Untouched = 'untouched',
   CategoriesIncomplete = 'categories incomplete',
   DetailsIncomplete = 'details incomplete',
   FilesIncomplete = 'files incomplete',
   Completed = 'completed',
   HasNoProducts = 'has no products',
   UnderReview = 'Under Review',
   RequiresUpdate = 'Requires Update',
   Draft = 'Draft',
}

export const useAccountStatus = (userType?: string) => {
   const { user } = useAuth();
   let status: AccountStatuses = AccountStatuses.Untouched;

   /* -------------------- Incomplete steps in user account -------------------- */
   let incomplete_steps: StepsList[] = ['categories', 'details', 'files'];

   /**
    * Each step has properties to be added by the user
    * The completion of specific step will change the UI respectivly
    */

   const step0 = ['business_categories'];
   const step1 = ['business_name', 'description'];
   const step2 =
      userType !== 'coach'
         ? [
              'cr_file',
              'cr_number',
              'cr_expire_date',
              'tourism_license_file',
              'tourism_number',
              'tourism_expire_date',
              'vat_reg_file',
           ]
         : ['tourism_license_file', 'tourism_number', 'tourism_expire_date'];

   /**
    * Makes sure that passed step is filled by the user
    * @param arr
    * @returns {boolean}
    */
   function validateStepKeys(arr: string[]) {
      return arr.every(key => {
         const item = user?.[key];
         if (Array.isArray(item || [])) return (item || []).length > 0;
         if (typeof item === 'object') {
            return Object.keys(item || {}).length > 0;
         }
         return !!item;
      });
   }

   if (validateStepKeys(step0)) {
      const list = [...incomplete_steps];
      incomplete_steps = list.filter(s => s !== 'categories');
   }
   if (validateStepKeys(step1)) {
      const list = [...incomplete_steps];
      incomplete_steps = list.filter(s => s !== 'details');
   }
   if (validateStepKeys(step2)) {
      const list = [...incomplete_steps];
      incomplete_steps = list.filter(s => s !== 'files');
   }

   if (incomplete_steps.length < 3) {
      if (incomplete_steps.length > 0) {
         switch (incomplete_steps[0]) {
            case 'categories':
               status = AccountStatuses.CategoriesIncomplete;
               break;
            case 'details':
               status = AccountStatuses.DetailsIncomplete;
               break;
            case 'files':
               status = AccountStatuses.FilesIncomplete;
               break;
            default:
               status = AccountStatuses.Untouched;
         }
      } else {
         status = AccountStatuses.Completed;
      }
   }

   if (user?.status === 'Under Review') status = AccountStatuses.UnderReview;
   if (user?.status === 'Requires Update') status = AccountStatuses.RequiresUpdate;

   if (status === AccountStatuses.Completed && !user?.profile_strength?.published_first_product)
      status = AccountStatuses.HasNoProducts;

   return { status, incomplete_steps };
};
