import { NextLink, Translate } from '~/i18n';
import { Reseller, Supplier } from '~/svgs';

const SignupOptions = () => {
   return (
      <>
         <div className="py-5 md:py-[120px]">
            <div className="text-[28px] text-center font-semibold pb-7">
               <Translate id="auth:which describes your business" />
            </div>
            <NextLink href="/supplier/login">
               <div className="bg-[#F7F8F9] rounded-default mb-6 text-center md:ltr:text-left md:rtl:text-right">
                  <div className="py-4 px-6 flex md:flex-row items-center justify-center flex-col">
                     <div className="md:bg-white p-3 rounded-default">
                        <Supplier height={96} width={96} className="p-1" />
                     </div>
                     <div className="px-4">
                        <div className="text-[20px] font-semibold ">
                           <Translate id="common:supplier" />
                        </div>
                        <div className="text-sm ">
                           <Translate id="auth:supplier signup" />
                        </div>
                     </div>
                  </div>
               </div>
            </NextLink>

            <NextLink href="/reseller/signup">
               <div className="bg-[#F7F8F9] rounded-default text-center md:ltr:text-left md:rtl:text-right">
                  <div className="py-4 px-6 flex md:flex-row items-center flex-col">
                     <div className="md:bg-white p-3 rounded-default">
                        <Reseller height={96} width={96} className="p-1" />
                     </div>

                     <div className="px-4">
                        <div className="text-[20px] font-semibold  ">
                           <Translate id="common:reseller" />
                        </div>
                        <div className="text-sm ">
                           <Translate id="auth:reseller signup" />
                        </div>
                     </div>
                  </div>
               </div>
            </NextLink>
         </div>
         <div className="text-center italic ">
            <Translate id="common:powered by" />
         </div>
      </>
   );
};

export default SignupOptions;
