import cn from 'classnames';
import Image from 'next/image';
import { useTranslate } from '~/i18n';
import LangSwitcher from '../common/lang-switcher';
import { Section } from '../common/section';

type AuthLayoutProps = {
   bgImg: string;
   children: React.ReactNode;
};

const AuthLayout = ({ bgImg, children }: AuthLayoutProps) => {
   const { lang } = useTranslate();
   return (
      <div
         className="relative flex items-center h-full bg-center bg-no-repeat bg-cover"
         // style={{ backgroundImage: `url(${bgImg})` }}
      >
         <Image
            alt="Background Image"
            className="w-full h-full bg-center rtl:scale-x-[-1]"
            layout="fill"
            objectFit="cover"
            priority
            src={bgImg}
            quality={70}
         />
         <div className="container z-10 flex items-start px-4 mx-auto lg:flex-row-reverse py-14">
            <Section
               className={cn(
                  'w-full lg:w-1/2 xl:w-5/12 lg:ltr:mr-16 lg:rtl:ml-16 px-6 md:px-12 py-10'
               )}>
               <div className="flex justify-end w-full">
                  <LangSwitcher
                     href={lang === 'ar' ? '/en' : '/ar'}
                     className="!text-2xl"
                     text={lang === 'ar' ? 'English' : 'عربي'}
                  />
               </div>
               <div className="relative w-24 h-20 mx-auto md:w-[225px] md:h-[125px]">
                  <Image layout="fill" src="/images/logo.png" className="object-contain" />
               </div>
               {children}
            </Section>
         </div>
      </div>
   );
};

export default AuthLayout;
