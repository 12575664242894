import { CheckedListItem, UncheckedListItem, WarningIcon } from '~/svgs';
import cn from 'classnames';

type CheckpointItemProps = {
   isCurrent?: boolean;
   isCompleted?: boolean;
   title?: React.ReactNode;
   isUnderReview?: boolean;
   requiresUpdate?: boolean;
   children?: React.ReactNode;
};

export const CheckpointItem = ({
   children,
   isCompleted,
   isCurrent,
   title,
   isUnderReview,
   requiresUpdate,
}: CheckpointItemProps) => {
   return (
      <li className={cn('px-8 py-3', isCurrent && 'bg-gray-100')}>
         <div className="flex items-center">
            {isCompleted ? (
               <CheckedListItem />
            ) : isCurrent ? (
               requiresUpdate ? (
                  <WarningIcon width={31} height={31} />
               ) : (
                  <UncheckedListItem
                     className={cn(
                        '!stroke-current',
                        isUnderReview ? 'text-[#FAA515]' : ' text-[#00A248]'
                     )}
                  />
               )
            ) : (
               <div className="w-8 h-8 bg-gray-100 rounded-full"></div>
            )}
            <span className={cn(isCurrent && 'font-bold', 'flex-grow ltr:ml-4 rtl:mr-4')}>
               {/* <Translate id="dashboard:create an account" /> */}
               {title}
            </span>
            {children}
         </div>
      </li>
   );
};
