import { Translate } from '~/i18n';

type Item = {
   href: string;
   icon: string;
   text: React.ReactNode;
   exact?: boolean;
   needsReview?: boolean;
};
export type LinkAttributes = {
   children?: Omit<Item, 'icon'>[];
} & Item;

export type OnboardLinkAttributes = Omit<Item, 'icon'>;

export const supplier_links: LinkAttributes[] = [
   {
      href: '/supplier/dashboard',
      icon: '/icons/header/dashboard.svg',
      text: <Translate id="dashboard:dashboard" />,
      exact: true,
   },
   {
      href: '/supplier/products',
      icon: '/icons/header/products.svg',
      text: <Translate id="common:products" />,
      children: [
         {
            text: <Translate id="dashboard:products list" />,
            href: '/supplier/products',
            // needsReview: true,
         },
         {
            text: <Translate id="createproduct:create product" />,
            href: '/supplier/products/main-details',
            // needsReview: true,
         },
      ],
   },
   {
      href: '/supplier/orders',
      icon: '/icons/header/finance.svg',
      text: <Translate id="dashboard:finance" />,
      children: [
         // {
         //    text: <Translate id="dashboard:orders list" />,
         //    href: '/supplier/orders',
         // },
         {
            text: <Translate id="dashboard:payout" />,
            href: '/supplier/orders/payout',
            // needsReview: true,
         },
      ],
   },
   {
      href: '/supplier/bookings',
      icon: '/icons/header/booking.svg',
      text: <Translate id="dashboard:bookings" />,
      children: [
         {
            text: <Translate id="bookings:sales feed" />,
            href: '/supplier/bookings/sales-feed',
            // needsReview: true,
         },
         // {
         //    text: <Translate id="dashboard:calendar" />,
         //    href: '/supplier/bookings/calendar',
         //    needsReview: true,
         // },
      ],
   },
   // {
   //    href: '/supplier/community',
   //    icon: '/icons/header/community.svg',
   //    text: <Translate id="dashboard:community" />,
   //    needsReview: true,
   // },
   {
      href: '/supplier/operation',
      icon: '/icons/header/operation.svg',
      text: <Translate id="dashboard:operation" />,
      // needsReview: true,
      children: [
         {
            text: <Translate id="operations:attendees list" />,
            href: '/supplier/operations/attendees-list',
            // needsReview: true,
         },
      ],
   },
];

export const reseller_links: LinkAttributes[] = [
   {
      href: '/reseller/dashboard',
      icon: '/icons/header/dashboard.svg',
      text: <Translate id="dashboard:dashboard" />,
      exact: true,
   },
   {
      href: '/reseller/products',
      icon: '/icons/header/products.svg',
      text: <Translate id="common:products" />,
      exact: true,
   },
   {
      href: '/reseller/orders',
      icon: '/icons/header/finance.svg',
      text: <Translate id="dashboard:finance" />,
      children: [
         // {
         //    text: <Translate id="dashboard:orders list" />,
         //    href: '/reseller/orders',
         // },
         {
            text: <Translate id="dashboard:payout" />,
            href: '/reseller/orders/payout',
         },
      ],
   },
   {
      href: '/reseller/bookings',
      icon: '/icons/header/booking.svg',
      text: <Translate id="dashboard:bookings" />,
      children: [
         {
            text: <Translate id="dashboard:orders" />,
            href: '/reseller/bookings/orders',
         },
         {
            text: <Translate id="dashboard:calendar" />,
            href: '/reseller/bookings/calendar',
         },
      ],
   },
];

export const coach_links: LinkAttributes[] = [
   {
      href: '/coach/dashboard',
      icon: '/icons/header/dashboard.svg',
      text: <Translate id="dashboard:dashboard" />,
      exact: true,
   },
   {
      href: '/coach/products',
      icon: '/icons/header/products.svg',
      text: <Translate id="common:products" />,
      children: [
         {
            text: <Translate id="dashboard:products list" />,
            href: '/coach/products',
         },
         {
            text: <Translate id="createproduct:create product" />,
            href: '/coach/products/main-details',
            needsReview: true,
         },
      ],
   },
   {
      href: '/coach/orders',
      icon: '/icons/header/finance.svg',
      text: <Translate id="dashboard:finance" />,
      children: [
         // {
         //    text: <Translate id="dashboard:orders list" />,
         //    href: '/coach/orders',
         // },
         {
            text: <Translate id="dashboard:payout" />,
            href: '/coach/orders/payout',
            needsReview: true,
         },
      ],
   },
   {
      href: '/supplier/bookings',
      icon: '/icons/header/booking.svg',
      text: <Translate id="dashboard:bookings" />,
      children: [
         {
            text: <Translate id="bookings:sales feed" />,
            href: '/supplier/bookings/sales-feed',
         },
         // {
         //    text: <Translate id="dashboard:calendar" />,
         //    href: '/supplier/bookings/calendar',
         //    needsReview: true,
         // },
      ],
   },
   // {
   //    href: '/supplier/community',
   //    icon: '/icons/header/community.svg',
   //    text: <Translate id="dashboard:community" />,
   //    needsReview: true,
   // },
   {
      href: '/supplier/operation',
      icon: '/icons/header/operation.svg',
      text: <Translate id="dashboard:operation" />,
      needsReview: true,
   },
];

export const onboard_links: OnboardLinkAttributes[] = [
   // {
   //    href: '/supplier/profile/details',
   //    text: <Translate id="profile:business profile" />,
   //    exact: true,
   // },
   {
      href: '/supplier/profile/files',
      text: <Translate id="profile:legal documents" />,
      exact: true,
   },
   // {
   //    href: '/supplier/profile/plans',
   //    text: <Translate id="profile:business plans" />,
   //    exact: true,
   // },
   // {
   //    href: '/supplier/profile/agreement',
   //    text: <Translate id="profile:agreement" />,
   //    exact: true,
   // },
];
