import NProgress from 'nprogress';
import Router from 'next/router';
import cn from 'classnames';
import { useLayout } from '../provider';
import { onboard_links } from '../_links';
import { OnboardHeaderLink } from './onboard-link';
import { useRef } from 'react';

const OnboardHeader = () => {
   const { open } = useLayout();
   // const router = useRouter();
   const menuRef = useRef<HTMLDivElement>(null);

   Router.events.on('routeChangeStart', () => NProgress.start());
   Router.events.on('routeChangeComplete', () => NProgress.done());
   Router.events.on('routeChangeError', () => NProgress.done());
   // const userType = router.asPath.includes('reseller')
   //    ? 'reseller'
   //    : router.asPath.includes('coach')
   //    ? 'coach'
   //    : router.asPath.includes('supplier')
   //    ? 'supplier'
   //    : 'trainer';

   return (
      <header className="fixed top-0 z-50 h-screen lg:sticky ltr:left-0 rtl:right-0">
         <nav
            ref={menuRef}
            className={cn(
               'w-72 h-full lg:inline-block transition-all duration-[250ms] bg-[#0648B4]',
               open ? 'visible' : 'hidden'
            )}>
            <div className="py-6">
               <img width="120px" src="/images/logo-light.svg" alt="" className="m-auto my-10" />
               <ul>
                  {onboard_links.map((link, index) => (
                     <li key={index} className="mb-4">
                        <OnboardHeaderLink {...link} />
                     </li>
                  ))}
               </ul>
            </div>
         </nav>
      </header>
   );
};

export default OnboardHeader;
