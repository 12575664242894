import { useState } from 'react';
import { NextLink } from '~/i18n';
import { OnboardLinkAttributes } from '../_links';
import css from './styles.module.css';
import cn from 'classnames';
import { useAccountStatus, useAuth } from '~/auth';
import { AccountStatuses } from '~/auth/use-account-status';
import { ActionPreventedAlert } from '~/components/common/alert/action-prevented';

export const OnboardHeaderLink = ({ href, text, needsReview }: OnboardLinkAttributes) => {
   const { user } = useAuth();
   const { status } = useAccountStatus();
   const [showPrevent, setShowPrevent] = useState(false);

   const underReview = status === AccountStatuses.UnderReview;
   const notReviewed = (needsReview && underReview) || false;
   const hasBusinessName = user?.business_name;
   const hasLegalFIles = user?.cr_file;

   const closePrevent = () => setShowPrevent(false);

   const handleUnderReviewClick = (e: any, prevent: boolean) => {
      if (prevent) {
         e.preventDefault();
         setShowPrevent(true);
      }
   };

   const isStepComplete = (path: string) => {
      let status = false;
      switch (path) {
         case '/supplier/profile/details':
            status = hasBusinessName ? true : false;
            break;
         case '/supplier/profile/files':
            status = hasLegalFIles ? true : false;
            break;
         // case '/supplier/profile/plans':
         //    status = isSubscribed ? true : false;
         //    break;
         default:
            status = false;
            break;
      }
      return status;
   };

   return (
      <div className="relative">
         <ActionPreventedAlert open={showPrevent} onClose={closePrevent} />
         <NextLink
            onClick={(e: any) => handleUnderReviewClick(e, notReviewed)}
            href={href}
            className={css.onboard_link}
            activeClassName={css.onboard_active}>
            <div className="flex justify-between">
               <span className={cn(notReviewed && '!opacity-40')}>{text}</span>
               <img
                  className="ltr:mr-3 rtl:ml-3"
                  width="14px"
                  src={
                     isStepComplete(href)
                        ? '/icons/circle-checked.png'
                        : '/icons/circle-outline.png'
                  }
                  alt="Unchecked icon"
               />
            </div>
         </NextLink>
      </div>
   );
};
