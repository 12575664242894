import { FC, Fragment } from 'react';
import cn from 'classnames';
import Header from './header';
import OnboardHeader from './header/onboard-header';
import Footer from './footer';
import { Subheader } from './subheader';
import { LayoutProvider } from './provider';
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                 MAIN LAYOUT                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

type LayoutProps = {
   hideHeader?: boolean;
   hideSubheader?: boolean;
   noContainer?: boolean;
   AdditionalLayout: any;
   showOnboardHeader?: boolean;
};

const Layout: FC<LayoutProps> = ({
   children,
   AdditionalLayout,
   hideHeader,
   hideSubheader,
   noContainer,
   showOnboardHeader,
}) => {
   const Tag = AdditionalLayout || Fragment;
   return (
      <LayoutProvider>
         <div className="flex flex-1">
            {!hideHeader && !showOnboardHeader ? <Header /> : ''}
            {showOnboardHeader && <OnboardHeader />}
            <main>
               {!hideSubheader && <Subheader hideLogo={showOnboardHeader} />}
               <div className={cn(!noContainer ? 'container px-2 mx-auto my-14' : 'h-full')}>
                  <Tag>{children}</Tag>
               </div>
            </main>
         </div>
         <Footer />
      </LayoutProvider>
   );
};

export default Layout;
