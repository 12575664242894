import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useAuth } from '~/auth';
import { Translate, useTranslate } from '~/i18n';
import { NotificationProps } from '~/interfaces/notification';
import { Person, ThreeDotsIcon } from '~/svgs';
import useTheme, { Theme } from '~/theme';
import Axios from '~/utils/axios';
import { getNotificationType } from './getNotificationType';

type NotificationItemType = {
   notification: NotificationProps;
   customClassName?: string;
   userType?: string;
};

const NotificationItem = ({ notification, customClassName, userType }: NotificationItemType) => {
   const { lang, translate } = useTranslate();
   const router = useRouter();
   const [isRead, setIsRead] = useState(notification?.is_read);
   const { updateUser, user } = useAuth();
   const { theme } = useTheme();
   const userRoute = theme === Theme.SUPPLIER ? 'supplier' : 'reseller';
   const timeAgo = (date: any) => {
      const now = DateTime.fromJSDate(new Date());
      const notificationDate = DateTime.fromJSDate(new Date(date?.date?.replace(' ', 'T')));
      const diffInMonths = now.diff(notificationDate, ['months', 'days', 'hours']).toObject();

      return (diffInMonths.months || 0) > 0 ? (
         <Translate id="notification:months ago" values={{ months: diffInMonths.months as any }} />
      ) : (diffInMonths.days || 0) > 0 ? (
         <Translate id="notification:days ago" values={{ days: diffInMonths.days as any }} />
      ) : (diffInMonths.hours || 0) > 1 ? (
         <Translate
            id="notification:hours ago"
            values={{ hours: Math.floor(diffInMonths.hours || 0) as any }}
         />
      ) : (
         <Translate id="notification:less than an hour ago" />
      );
   };

   const updateAsRead = async () => {
      try {
         await Axios.post(`/hy-${userType}/v1/notifications/${notification.id}/mark-read`);
         setIsRead(true);
         updateUser({
            ...user,
            current_user: {
               ...user.current_user,
               unread_notifications_count: user.current_user.unread_notifications_count - 1,
            },
         });
      } catch (error) {
         console.log(error);
      }
   };

   const updateAsUnRead = async () => {
      try {
         Axios.post(`/hy-${userType}/v1/notifications/${notification.id}/mark-unread`);
         setIsRead(false);
         updateUser({
            ...user,
            current_user: {
               ...user.current_user,
               unread_notifications_count: user.current_user.unread_notifications_count + 1,
            },
         });
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div
         className={classNames(
            customClassName,
            'flex justify-between text-gray-600 sm:p-7 p-4 space-x-5 rtl:space-x-reverse overflow-visible hover:bg-gray-50'
         )}>
         <div
            onClick={() => {
               getNotificationType({
                  translate: translate,
                  router: router,
                  lang: lang,
                  notification: notification,
                  userRoute: userRoute,
               })?.route(),
                  updateAsRead();
            }}
            className="flex items-center space-x-3 cursor-pointer rtl:space-x-reverse">
            <div className="relative flex-shrink-0">
               {notification?.data?.product?.cover_image ? (
                  <img
                     height={50}
                     width={50}
                     className="rounded-full object-cover !h-12 !w-12"
                     src={notification?.data?.product?.cover_image}
                  />
               ) : (
                  <div className="bg-gray-100 h-12 w-12 flex justify-center rounded-full items-center">
                     <Person />
                  </div>
               )}

               {!isRead ? (
                  <div className="absolute top-0 rtl:left-0 ltr:right-0 bg-blue-500 w-4 h-4 rounded-full border-white border-2"></div>
               ) : null}
            </div>
            <div>
               <div className="flex flex-wrap">
                  <div>
                     {
                        getNotificationType({
                           translate: translate,
                           router: router,
                           lang: lang,
                           notification: notification,
                           userRoute: userRoute,
                        })?.text
                     }
                  </div>
                  <div className="font-bold mx-1">{notification?.data?.product?.name[lang]}</div>
               </div>
               <div className={classNames(!isRead ? 'text-blue-500' : null)}>
                  {timeAgo(notification?.created_at)}
               </div>
            </div>
         </div>

         <Menu as="div" className="whitespace-nowrap relative">
            <Menu.Button aria-label="notification">
               <ThreeDotsIcon />
            </Menu.Button>

            <Menu.Items className="rtl:left-0 z-10 ltr:right-0 ltr:origin-top-right bg-white absolute text-gray-400 text-sm rounded-lg shadow-lg ring-opacity-5 focus:outline-none">
               <Menu.Item disabled={isRead}>
                  <div onClick={() => updateAsRead()} className="p-2 px-3 cursor-pointer">
                     <Translate id="notification:mark as read" />
                  </div>
               </Menu.Item>
               <Menu.Item disabled={!isRead}>
                  <div onClick={() => updateAsUnRead()} className="p-2 px-3 cursor-pointer">
                     <Translate id="notification:mark as unread" />
                  </div>
               </Menu.Item>
            </Menu.Items>
         </Menu>
      </div>
   );
};

export default NotificationItem;
