import { AppProps } from 'next/app';
import Layout from '~/components/layout';
import { getLang } from '~/utils/translate';
import { initGTM, initHotjar } from '~/utils/analytics';
import { useEffect } from 'react';
import { AuthProvider } from '~/auth';
import { TranslationProvider } from '~/i18n';
import { DefaultSeo } from 'next-seo';
import config from '~/translation.json';
import { Theme, ThemeProvider } from '~/theme';
import { ReactQueryDevtools } from 'react-query/devtools';
// import { ChatWidget } from '~/components/common/chat-widget';
import { pdfjs } from 'react-pdf';
import { QueryClient, QueryClientProvider } from 'react-query';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Toaster } from 'react-hot-toast';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import '../css/tailwind.css';
import '../css/web.css';
import '../css/theme.css';
import '../css/nprogress.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';

const { languages } = config;

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                              NEXTJS IS AWESOME                             */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
SwiperCore.use([Pagination, Navigation, Autoplay]);
const App = ({ Component, pageProps, router }: AppProps) => {
   /*
    * Make sure to only use it when necessary as this function will run on every page
    * thats why we are using analytics in here
    **/
   useEffect(() => {
      initGTM();
      initHotjar();
   }, []);

   /* ---------------------- Application current language ---------------------- */
   const lang = getLang(router);
   const userType = router.asPath.includes('reseller')
      ? 'reseller'
      : router.asPath.includes('supplier')
      ? 'supplier'
      : 'coach';

   // Manipulating html element for lang and dir attributes
   useEffect(() => {
      const langs = languages as Record<string, string>;
      const dir = langs[lang];
      document.documentElement.lang = lang;
      document.documentElement.dir = dir;
      document.body.dir = dir;
   }, [lang]);

   const translations = require(`~/translations/${lang}`).default;
   const theme = router.asPath?.split('/')[2] as Theme;
   const { hideHeader, hideSubheader, noContainer, AdditionalLayout, showOnboardHeader } =
      Component as any;

   const queryClient = new QueryClient();
   return (
      <GoogleReCaptchaProvider
         reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_KEY || ''}
         scriptProps={{ defer: true }}>
         <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
               <TranslationProvider lang={lang} translations={translations}>
                  <DefaultSeo
                     title="HY Pro"
                     description="Hala Yalla Pro"
                     nofollow={false}
                     noindex={false}
                  />
                  <AuthProvider
                     userType={userType}
                     afterLogoutTo={`/${lang}/${theme}/login`}
                     afterLoginTo={`/${lang}/${userType}/dashboard`}>
                     <Toaster position="top-center" reverseOrder={false} />
                     <Layout
                        hideHeader={hideHeader}
                        hideSubheader={hideSubheader}
                        noContainer={noContainer}
                        AdditionalLayout={AdditionalLayout}
                        showOnboardHeader={showOnboardHeader}>
                        <Component {...pageProps} />
                        {/* <ChatWidget /> */}
                     </Layout>
                  </AuthProvider>

                  {/* ------------------------------- App styling ------------------------------ */}
                  {/* this makes sure that main take the full available height of the broweser */}
                  <style jsx global>{`
                     #__next {
                        display: flex;
                        flex-direction: column;
                        min-height: 100vh;
                     }
                     #__next main {
                        flex: 1;
                        background-color: #f8f8f8;
                     }
                     :root {
                        --primary: ${theme === Theme.RESELLER ? '234, 88, 12' : '29, 78, 216;'};
                     }
                  `}</style>
               </TranslationProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen />
         </QueryClientProvider>
      </GoogleReCaptchaProvider>
   );
};

export default App;
