import cn from 'classnames';

type Directions = 'up' | 'down' | 'left' | 'right';
type ArrowProps = {
   direction?: Directions;
   animate?: boolean;
};
export const Arrow = ({
   direction: d = 'right',
   animate,
   className,
   ...props
}: ArrowProps & React.HTMLAttributes<SVGElement>) => {
   return (
      <svg
         width="15"
         height="15"
         viewBox="0 0 15 15"
         fill="none"
         className={cn(
            'transform',
            animate && 'transition-all',
            d === 'down' && 'rotate-90',
            d === 'up' && '-rotate-90',
            d === 'right' && 'rtl:rotate-180',
            d === 'left' && 'ltr:rotate-180',
            className
         )}
         xmlns="http://www.w3.org/2000/svg"
         {...props}>
         <g clipPath="url(#clip0)">
            <path
               d="M11.775 7.49931C11.775 7.76814 11.6724 8.03694 11.4675 8.24189L5.0178 14.6916C4.60752 15.1018 3.94231 15.1018 3.5322 14.6916C3.12208 14.2814 3.12208 13.6164 3.5322 13.2061L9.23927 7.49931L3.5324 1.79253C3.12228 1.38225 3.12228 0.717247 3.5324 0.307164C3.94251 -0.103318 4.60772 -0.103318 5.018 0.307164L11.4677 6.75672C11.6726 6.96178 11.775 7.23058 11.775 7.49931Z"
               fill="currentcolor"
            />
         </g>
         <defs>
            <clipPath id="clip0">
               <rect width="15" height="15" fill="white" transform="translate(0 15) rotate(-90)" />
            </clipPath>
         </defs>
      </svg>
   );
};
