import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

const LayoutContext = createContext({
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   toggleMenu: (_open?: boolean) => {},
   open: false,
});

export const useLayout = () => {
   return useContext(LayoutContext);
};

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
   const [open, setOpen] = useState(false);

   const toggleMenu = useCallback((o?: boolean) => {
      if (o === true || o === false) {
         localStorage.setItem('expand', o + '');
         setOpen(o);
      } else {
         setOpen(prev => {
            localStorage.setItem('expand', !prev + '');
            return !prev;
         });
      }
   }, []);

   useEffect(() => {
      setOpen(localStorage.getItem('expand') === 'true');
   }, []);

   return <LayoutContext.Provider value={{ toggleMenu, open }}>{children}</LayoutContext.Provider>;
};
