import { createContext } from 'react';
import messages from '~/translations/en';
export type Directions = 'rtl' | 'ltr';

type PathsToString<T> = T extends string
   ? []
   : {
        [K in Extract<keyof T, string>]: [K, ...PathsToString<T[K]>];
     }[Extract<keyof T, string>];

type JoinPaths<T extends string[], D extends string> = T extends []
   ? never
   : T extends [infer F]
   ? F
   : T extends [infer F, ...infer R]
   ? F extends string
      ? `${F}${D}${JoinPaths<Extract<R, string[]>, D>}`
      : never
   : string;

type DottedTranslationObjectString = JoinPaths<PathsToString<typeof messages>, ':'>;

export type TranslateProps = {
   /** key inside translation files  eg: common:home_page */
   id: DottedTranslationObjectString;
   /** if key wasn't found, this will be used instead */
   fallback?: string;
   values?: Record<string, string>;
};

export interface ITranslationContext {
   translate: (options: TranslateProps) => any;
   lang: string;
   dir: Directions;
}

const TranslationContext = createContext<ITranslationContext>({
   /* -------------------------------------------------------------------------- */
   /*                             Translate function                             */
   /* -------------------------------------------------------------------------- */

   /**
    * @description will search translation files based on current language
    * unless specific language prop was passed!
    */
   translate: ({ id }: TranslateProps) => id,
   lang: '',
   dir: 'ltr',
});

export default TranslationContext;
