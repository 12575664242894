import { useState, useEffect } from 'react';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { useTranslate } from '~/i18n';
import useAuth from './useAuth';
import { SubmitLoader } from '~/components/common/loaders/submit';
import useTheme, { Theme } from '~/theme';
type LoggedState = 'checking' | true | false;
import CryptoJS from 'crypto-js';

/* -------------------------------------------------------------------------- */
/*                             AUTHENTICATE ME :)                             */
/* -------------------------------------------------------------------------- */

/**
 * @description Pages wrapped by this component will redirect the user if he is logged in
 * good for pages such as (login,register,etc..)
 */

export const withRedirectOnAuth = (Component: React.ComponentType) => {
   const WithRedirect = (props: any) => {
      const [authenticated, setAuth] = useState<LoggedState>('checking');
      const router = useRouter();
      const { lang } = useTranslate();
      const { settings } = useAuth();
      const { theme } = useTheme();
      const userType =
         theme === Theme.RESELLER ? 'reseller' : theme === Theme.COACH ? 'coach' : 'supplier';

      useEffect(() => {
         const token = cookie.get('token');
         const AES_KEY = CryptoJS.enc.Base64.parse(process?.env?.NEXT_PUBLIC_ENCRYPT_KEY || '');
         const customHref = settings.onAuthTo || `/${lang}/${userType}/dashboard`;
         if (router?.query?.token) {
            const _token: any = router?.query?.token;
            const parsed_msg = JSON.parse(atob(_token));
            const decrypted: any = CryptoJS.AES.decrypt(parsed_msg.value, AES_KEY, {
               iv: CryptoJS.enc.Base64.parse(parsed_msg.iv),
            }).toString(CryptoJS.enc.Utf8);
            // decrypted string in not in parsable format. So using regex to extract token
            const _decoded_token = decrypted
               ?.match?.(/(?:"[^"]*"|^[^"]*$)/)[0]
               ?.replace?.(/"/g, '');
            const expiry = Number(process?.env?.NEXT_PUBLIC_TOKEN_COOKIES_AGE || 1);
            cookie.set('token', _decoded_token, { path: '/', expires: expiry });
            router.replace(customHref);
         } else if (!token) {
            setAuth(false);
         } else {
            router.replace(customHref);
         }
      }, [lang, router, settings.onAuthTo, userType]);

      return authenticated === false && <Component {...props} />;
   };
   Object.keys(Component).forEach(key => {
      (WithRedirect as any)[key] = (Component as any)[key];
   });
   return WithRedirect;
};

type withAuthConfig = {
   /** If true, it means that the user will comeback here upon logging in */
   comeback?: boolean;
   /** By default its going to redirect the user to login page if he is not authenticated, if this is passed it will redirect him to the specified path */
   redirectTo?: {
      as: string;
      href: string;
   };
};

/**
 * @description Pages wrapped by this component will redirect the user if he is NOT logged in
 */
export function withAuth(Component: React.ComponentType<any>, config?: withAuthConfig) {
   const WithAuth = (props: any) => {
      const [authenticated, setAuth] = useState<LoggedState>('checking');
      const router = useRouter();
      const { lang } = useTranslate();
      const { settings, userLoading } = useAuth();

      useEffect(() => {
         const token = cookie.get('token');
         if (token) setAuth(true);
         else {
            if (config?.comeback) {
               const href = router.asPath;

               const redirect = {
                  redirect: href,
               };
               sessionStorage.setItem('redirect', JSON.stringify(redirect));
            }

            const customHref = settings.loginPath || `/${lang}/signup`;
            router.replace(config?.redirectTo || customHref);
         }
      }, [lang, router, settings.loginPath]);

      return (
         authenticated &&
         (userLoading ? (
            <div className="flex items-center justify-center h-20">
               <SubmitLoader />
            </div>
         ) : (
            <Component {...props} />
         ))
      );
   };
   Object.keys(Component).forEach(key => {
      (WithAuth as any)[key] = (Component as any)[key];
   });
   return WithAuth;
}
