import { Translate } from '~/i18n';
import { Button } from '../button';
import Modal from '../modal';
import { useAccountStatus } from '~/auth';
import { AccountStatuses } from '~/auth/use-account-status';

type ActionPreventedAlertProps = {
   open: boolean;
   onClose: () => any;
};
export const ActionPreventedAlert = ({ open, onClose }: ActionPreventedAlertProps) => {
   const { status } = useAccountStatus();
   return (
      <Modal open={open} onClickOutside={onClose} node="prevent-action">
         <Modal.Body className="p-6">
            <div className="flex flex-col items-center justify-center text-center">
               <div className="text-2xl font-bold">
                  <Translate id="common:sorry" />
               </div>
               <p>
                  {status === AccountStatuses.RequiresUpdate ? (
                     <Translate id="common:action denied for profile rejection" />
                  ) : (
                     <Translate id="common:can't do action" />
                  )}
               </p>
               <p>
                  <Translate parseHTML id="common:contact team" />
               </p>
               <Button className="w-32 h-12 mt-6" theme="primary" onClick={onClose}>
                  <Translate id="common:ok" />
               </Button>
            </div>
         </Modal.Body>
      </Modal>
   );
};
