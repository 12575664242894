import cn from 'classnames';
import { MenuIcon, ProfileCompleteIcon } from '~/svgs';
import { useLayout } from './provider';
import { Menu } from '@headlessui/react';
import { Arrow } from '~/components/common/arrow';
import { NextLink, Translate, useTranslate } from '~/i18n';
import Link from 'next/link';
import { FC } from 'react';
import { useAccountStatus, useAuth } from '~/auth';
import LangSwitcher from '~/components/common/lang-switcher';
import useTheme, { Theme } from '~/theme';
import useNotificationList from '~/apis/notification/notifications-list';
import NotificationItem from '~/components/notifications/notification-item';
import { ProfileSection } from '~/components/supplier/incomplete-dashboard/profile.section';
import { ProfileSectionReseller } from '~/components/reseller/incomplete-dashboard/profile.section';
import { ProfileSectionCoach } from '~/components/coach/incomplete-dashboard/profile.section';
// import useBookingsList from '~/apis/useBookingsList.api';
import { AccountStatuses } from '~/auth/use-account-status';

export const Subheader = ({ hideLogo = false }) => {
   const { toggleMenu } = useLayout();
   const { lang } = useTranslate();

   const provider_links = (provider_path: string) => [
      {
         img: '/images/profile/person.svg',
         redirectTo: `/${lang}/${provider_path}/profile/personal-info`,
         name: <Translate id="profile:personal info" />,
      },
      {
         img: '/images/profile/bag.svg',
         redirectTo: `/${lang}/${provider_path}/profile/business/details`,
         name: <Translate id="profile:business info" />,
      },
      {
         img: '/images/profile/doc.svg',
         redirectTo: `/${lang}/${provider_path}/profile/business/files`,
         name: <Translate id="profile:legal documents" />,
      },
      {
         img: '/images/profile/money.svg',
         redirectTo: `/${lang}/${provider_path}/profile/business/billing-info`,
         name: <Translate id="profile:billing info" />,
      },
      {
         img: '/images/profile/paper.svg',
         redirectTo: `/${lang}/${provider_path}/profile/business/payout`,
         name: <Translate id="payout:payout requests" />,
      },
      {
         img: '/images/profile/question.svg',
         redirectTo: `/${lang}/${provider_path}/faq`,
         name: <Translate id="common:faq" />,
      },
      {
         img: '/images/profile/contact-support.svg',
         redirectTo: `/${lang}/${provider_path}/contact-support`,
         name: <Translate id="dashboard:contact support" />,
      },
   ];

   const reseller_links = [
      {
         img: '/images/profile/person.svg',
         redirectTo: `/${lang}/reseller/profile/personal-info`,
         name: <Translate id="profile:personal info" />,
      },
      {
         img: '/images/profile/bag.svg',
         redirectTo: `/${lang}/reseller/profile/business/details`,
         name: <Translate id="profile:business info" />,
      },
      {
         img: '/images/profile/doc.svg',
         redirectTo: `/${lang}/reseller/profile/business/files`,
         name: <Translate id="profile:legal documents" />,
      },
      {
         img: '/images/profile/money.svg',
         redirectTo: `/${lang}/reseller/profile/business/billing-info`,
         name: <Translate id="profile:billing info" />,
      },
      {
         img: '/images/profile/paper.svg',
         redirectTo: `/${lang}/reseller/profile/business/payout`,
         name: <Translate id="payout:payout requests" />,
      },
   ];
   const { theme } = useTheme();

   const links =
      theme === Theme.SUPPLIER
         ? provider_links('supplier')
         : theme === Theme.COACH
         ? provider_links('coach')
         : reseller_links;

   interface MenuLinkProps {
      name: string;
      img: string;
      redirectTo: string;
   }

   const { logout, user } = useAuth();
   const { status } = useAccountStatus();
   const { data: notificationList } = useNotificationList({
      pageNumber: 1,
      userType: theme === Theme.SUPPLIER || theme === Theme.COACH ? 'providers' : 'resellers',
   });
   // const { data: bookings } = useBookingsList({
   //    userType: theme === Theme.SUPPLIER ? 'providers' : 'resellers',
   // });
   const profileStrength = user?.profile_strength || {};
   const verifiedBusinessProfile = profileStrength?.verified_business_profile;

   const isProfileStrengthComplete =
      profileStrength?.published_first_product && verifiedBusinessProfile;

   const MenuLink: FC<MenuLinkProps> = ({ redirectTo, name, img }) => {
      return (
         <Link href={redirectTo}>
            <a className="flex items-center justify-between w-full text-gray-600 hover:bg-gray-100 focus:hover:bg-gray-100 h-11">
               <div className="flex">
                  <img width="15" height="15" className="mx-3" src={img} />
                  <span>{name}</span>
               </div>
               <Arrow direction="right" className="w-2 h-3 mx-6" />
            </a>
         </Link>
      );
   };

   const renderProgressBar = () => {
      let progressStrengthValue = 1;
      if (verifiedBusinessProfile) progressStrengthValue += 1;
      if (profileStrength?.published_first_product) progressStrengthValue += 1;

      return (
         <div className="flex items-center space-x-0.5 rtl:space-x-reverse flex-shrink-0">
            {[...Array(3)].map((_key, index) => (
               <div
                  key={index}
                  className={cn(
                     'sm:block hidden w-[24px] md:w-[48px] lg:w-[72px] h-2 sm:h-[10px]',
                     progressStrengthValue > index ? 'bg-primary' : ' bg-gray-100',
                     index === 0 && 'ltr:rounded-l-full rtl:rounded-r-full'
                  )}></div>
            ))}
            <div className="w-10 h-10 border-2 border-gray-100 rounded-full ltr:!-ml-1 rtl:!-mr-1 bg-white flex items-center justify-center">
               <img
                  src="/images/dashboard/trophy.svg"
                  alt=""
                  width="20px"
                  height="20px"
                  className="w-5 h-5"
               />
            </div>
         </div>
      );
   };

   const renderProgressBarReseller = () => {
      let progressStrengthValue = 1;
      if (verifiedBusinessProfile) progressStrengthValue += 1;
      if (user?.profile_strength?.resell_first_product) progressStrengthValue += 1;

      return (
         <div className="flex items-center space-x-0.5 rtl:space-x-reverse flex-shrink-0">
            {[...Array(3)].map((_key, index) => (
               <div
                  key={index}
                  className={cn(
                     'sm:block hidden w-[24px] md:w-[48px] lg:w-[72px] h-2 sm:h-[10px]',
                     progressStrengthValue > index ? 'bg-primary' : ' bg-gray-100',
                     index === 0 && 'ltr:rounded-l-full rtl:rounded-r-full'
                  )}></div>
            ))}
            <div className="w-10 h-10 border-2 border-gray-100 rounded-full ltr:!-ml-1 rtl:!-mr-1 bg-white flex items-center justify-center">
               <img
                  src="/images/dashboard/trophy.svg"
                  alt=""
                  width="20px"
                  height="20px"
                  className="w-5 h-5"
               />
            </div>
         </div>
      );
   };

   return (
      <div className="sticky inset-x-0 top-0 z-[100] flex items-center justify-between h-16 px-3 bg-white">
         <div className="flex items-center">
            <button onClick={() => toggleMenu()} className="block lg:hidden">
               <MenuIcon />
            </button>

            {!hideLogo && (
               <img
                  width="90px"
                  height="58.35px"
                  src="/images/logo.svg"
                  alt=""
                  className="mx-1 xs:mx-5"
               />
            )}

            <div className="hidden md:block">{/* <ModeSwitcher /> */}</div>

            {/* Profile Strength - Progress Indicator | Start*/}
            {!hideLogo && status === AccountStatuses.Completed ? (
               <div className="flex items-center">
                  <div className="px-1 sm:px-4">
                     {theme === Theme.SUPPLIER || theme === Theme.COACH
                        ? renderProgressBar()
                        : renderProgressBarReseller()}
                  </div>
                  <Menu as="div">
                     <Menu.Button
                        className="flex items-center justify-center rounded-full"
                        aria-label="Profile Strength">
                        <Arrow direction="down" className="w-3 h-3 opacity-40" />
                     </Menu.Button>
                     <Menu.Items className="rtl:origin-top-left ltr:left-0 xs:ltr:left-20 xs:rtl:right-20 rtl:right-0 ltr:origin-top-right absolute top-16 w-full xs:w-10/12 bg-white rounded-lg shadow-2xl lg:w-[650px] ring-opacity-5 focus:outline-none">
                        {isProfileStrengthComplete ? (
                           <div className="flex flex-col items-center justify-center h-[400px]">
                              <ProfileCompleteIcon />
                              <h1 className="my-2 text-2xl font-bold text-center capitalize">
                                 <Translate id="dashboard:congratulation" />
                              </h1>
                              <p className="px-4 text-center text-gray-400 text-md">
                                 <Translate id="dashboard:profile strength complete" />
                              </p>
                           </div>
                        ) : (
                           <div className="text-sm xs:text-base">
                              {theme === Theme.SUPPLIER ? (
                                 <ProfileSection hideProgressBar={true} />
                              ) : theme === Theme.COACH ? (
                                 <ProfileSectionCoach hideProgressBar={true} />
                              ) : (
                                 <ProfileSectionReseller hideProgressBar={true} />
                              )}
                           </div>
                        )}
                     </Menu.Items>
                  </Menu>
               </div>
            ) : null}
            {/* Profile Strength - Progress Indicator | End*/}
         </div>
         <div className="flex items-center space-x-1 sm:space-x-3 rtl:space-x-reverse md:ltr:mr-10 md:rtl:ml-10">
            <LangSwitcher
               href={lang === 'ar' ? '/en' : '/ar'}
               className="text-sm sm:text-lg"
               text={lang === 'ar' ? 'English' : 'عربي'}
            />

            {/* <Menu as="button" className="flex items-center ">
               <Menu.Button
                  className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full"
                  aria-label="Help">
                  <img alt="" src="/icons/header/question.svg" width="10px" height="17px" />
               </Menu.Button>
            </Menu> */}
            <Menu as="div">
               <div className="relative">
                  <Menu.Button
                     disabled={user?.current_user?.unread_notifications_count === 0}
                     className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full"
                     aria-label="Notification">
                     <img alt="" src="/icons/header/notification.svg" width="16px" height="16px" />

                     {user?.current_user?.unread_notifications_count > 0 && (
                        <div className="absolute z-10 -top-1 p-1 rtl:-left-2 ltr:-right-2 bg-red-500 font-semibold text-white text-[10px] items-center flex justify-center h-6 w-6 rounded-full border-white border-2">
                           {user?.current_user?.unread_notifications_count}
                        </div>
                     )}
                  </Menu.Button>
               </div>
               <Menu.Items className="absolute mt-4 bg-white divide-y divide-gray-100 rounded-lg shadow-lg rtl:origin-top-left rtl:left-24 ltr:origin-top-right top-14 ltr:right-24 ring-opacity-5 focus:outline-none">
                  <div className="px-4 py-3">
                     <div className="font-bold text-gray-500">
                        <Translate id="notification:notification" />
                     </div>
                     <NextLink
                        className="flex justify-end text-xs text-blue-400 cursor-pointer"
                        href={`/${
                           theme === Theme.SUPPLIER
                              ? 'supplier'
                              : theme === Theme.COACH
                              ? 'coach'
                              : 'reseller'
                        }/notifications`}>
                        <Translate id="common:see all" />
                     </NextLink>
                  </div>
                  {notificationList?.data.slice(0, 3).map((item: any) => (
                     <div key={item.id}>
                        <NotificationItem
                           userType={
                              theme === Theme.SUPPLIER || theme === Theme.COACH
                                 ? 'providers'
                                 : 'resellers'
                           }
                           customClassName="!p-3 !text-xs"
                           notification={item}
                        />
                     </div>
                  ))}
               </Menu.Items>
            </Menu>

            <Menu as="div" className="flex items-center ">
               <Menu.Button
                  className="flex items-center justify-center w-10 h-10 font-bold uppercase bg-gray-100 rounded-full"
                  aria-label="Account">
                  {user?.current_user?.name[0]}
               </Menu.Button>

               <Menu.Items className="absolute mt-4 overflow-hidden bg-white divide-y divide-gray-100 rounded-lg shadow-lg rtl:origin-top-left rtl:left-12 ltr:origin-top-right top-14 ltr:right-12 w-80 ring-opacity-5 focus:outline-none">
                  <div className="flex px-4 py-3">
                     <div className="flex items-center justify-center w-10 h-10 font-bold uppercase bg-gray-100 rounded-full">
                        {user?.current_user?.name[0]}
                     </div>
                     <div
                        className={cn(
                           'mx-3 ltr:text-left rtl:text-right',
                           !verifiedBusinessProfile && 'mt-2'
                        )}>
                        <div className="text-sm font-bold">
                           {verifiedBusinessProfile ? (
                              user?.current_user?.name
                           ) : (
                              <Translate id="profile:halayalla partner" />
                           )}
                        </div>
                        {verifiedBusinessProfile && (
                           <NextLink
                              href={`/${theme}/profile/personal-info`}
                              className="text-xs text-primary">
                              <Translate id="profile:view profile" />
                           </NextLink>
                        )}
                     </div>
                  </div>
                  {verifiedBusinessProfile &&
                     links?.map((item: any) => (
                        <Menu.Item key={item.name}>
                           <MenuLink name={item.name} img={item.img} redirectTo={item.redirectTo} />
                        </Menu.Item>
                     ))}
                  <Menu.Item>
                     <button
                        onClick={() => logout()}
                        className="flex items-center justify-between w-full text-gray-600 hover:bg-gray-100 focus:hover:bg-gray-100 h-11">
                        <div className="flex">
                           <img
                              width="15"
                              height="15"
                              className="mx-3"
                              src={'/images/profile/logout.svg'}
                           />
                           <span>
                              <Translate id="common:logout" />
                           </span>
                        </div>
                        <Arrow direction="right" className="w-2 h-3 mx-6" />
                     </button>
                  </Menu.Item>
               </Menu.Items>
            </Menu>
         </div>
      </div>
   );
};
