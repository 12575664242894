import { NextRouter } from 'next/router';
import { NotificationProps } from '~/interfaces/notification';

type getNotificationTypeProps = {
   router: NextRouter;
   lang: string;
   notification: NotificationProps;
   translate: (value: any) => any;
   userRoute: string;
};

export const getNotificationType = ({
   router,
   lang,
   notification,
   translate,
   userRoute,
}: getNotificationTypeProps) => {
   switch (notification.type) {
      case 'Halayalla\\Providers\\Notifications\\Product\\ProductSent':
         return {
            text: translate({ id: 'notification:product submitted' }),
            route: () =>
               router.push(`/${lang}/${userRoute}/products/${notification?.data?.product?._id}`),
         };
      case 'Halayalla\\Providers\\Notifications\\Payout\\PayoutSent':
         return {
            text: translate({ id: 'notification:payout sent for review' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/business/payout`),
         };
      case 'Halayalla\\Providers\\Notifications\\Payout\\PayoutApproved':
         return {
            text: translate({ id: 'notification:payout approved' }),
            route: () =>
               router.push(
                  `/${lang}/${userRoute}/payout-request/${notification.data.payout_request._id}`
               ),
         };
      case 'Halayalla\\Providers\\Notifications\\Payout\\PayoutRejected':
         return {
            text: translate({ id: 'notification:payout rejected' }),
            route: () =>
               router.push(
                  `/${lang}/${userRoute}/payout-request/${notification.data.payout_request._id}`
               ),
         };
      case 'Halayalla\\Providers\\Notifications\\Profile\\ProfileApproved':
         return {
            text: translate({ id: 'notification:profile approved' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/business/details`),
         };
      case 'Halayalla\\Providers\\Notifications\\Profile\\ProfileRejected':
         return {
            text: translate({ id: 'notification:profile rejected' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/business/details`),
         };
      case 'Halayalla\\Providers\\Notifications\\Profile\\ProfileSent':
         return {
            text: translate({ id: 'notification:profile sent for review' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/business/details`),
         };
      case 'Halayalla\\Providers\\Notifications\\Profile\\BankDetailsUpdates':
         return {
            text: translate({ id: 'notification:bank details updated' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/business/billing-info`),
         };
      case 'Halayalla\\Providers\\Notifications\\User\\EmailChanged':
         return {
            text: translate({ id: 'notification:user email changed' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/personal-info`),
         };
      case 'Halayalla\\Providers\\Notifications\\User\\MobileChanged':
         return {
            text: translate({ id: 'notification:user mobile changed' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/personal-info`),
         };
      case 'Halayalla\\Providers\\Notifications\\User\\PasswordChanged':
         return {
            text: translate({ id: 'notification:user password changed' }),
            route: () => router.push(`/${lang}/${userRoute}/profile/personal-info`),
         };
      case 'Halayalla\\Providers\\Notifications\\Product\\ProductApproved':
         return {
            text: translate({ id: 'notification:product approved' }),
            route: () =>
               router.push(`/${lang}/${userRoute}/products/${notification?.data?.product?._id}`),
         };
      case 'Halayalla\\Providers\\Notifications\\Product\\ProductRejected':
         return {
            text: translate({ id: 'notification:product rejected' }),
            route: () =>
               router.push(`/${lang}/${userRoute}/products/${notification?.data?.product?._id}`),
         };
   }
};
