import Loader from 'react-loader-spinner';
import cn from 'classnames';
export const SubmitLoader = ({
   className,
   size = '30px',
}: {
   className?: string;
   size?: string | number;
}) => {
   return (
      <div className={cn(className)}>
         <Loader width={size} height={size} color="#DADADA" type="TailSpin" />
      </div>
   );
};
